.modal-container {
  border-radius: var(--vp-border-radius-lg);
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: var(--vp-firstmost-element-z-index);
}

.gateway-modal {
  border-radius: var(--vp-border-radius-lg);
  background: white;
  box-shadow: 0px calc(var(--vp-box-shadow-size)*1) calc(var(--vp-box-shadow-size)*7) #C6C6C6;
  padding: calc(var(--vp-spacing) * 2);
  user-select: none;
  width: 22.5em;
}

.modal-title {
  align-items: center;
  color: var(--vp-skyline-blue);
  display: flex;
  font-family: var(--vp-font-open-sans-condensed-bold);
  font-size: calc(var(--vp-font-size) * 1.5);
  margin: 0;
  padding-bottom: var(--vp-spacing); 

  span {
    flex-grow: 1;
  }
}

.close-modal {
  border: none;
  background-color: transparent;
  padding: 0;
}

.shell-modal {
  background: transparent !important;
  background-color: transparent !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  border: none;
}

.modal-mode {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  border: none;
  background-color: #00000075;
}
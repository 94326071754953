@import "@skyline-platform/alchemy/style.css";

@font-face {
  font-family: 'opensans-regular'; /* Name your font family */
  src: url("../fonts/OpenSans-Regular.ttf") format("truetype"); /* TTF format */
  src: url("../fonts/OpenSans-Regular.ttf") format("woff"); /* TTF format */
  src: url("../fonts/OpenSans-Regular.ttf") format("woff2"); /* TTF format */

  /* Add additional formats (WOFF, WOFF2, etc.) for better compatibility */
  /* font-weight and font-style properties may also be specified */
}

@font-face {
  font-family: 'opensans-medium'; /* Name your font family */
  src: url("../fonts/OpenSans-Medium.ttf") format("truetype"); /* TTF format */
  src: url("../fonts/OpenSans-Medium.ttf") format("woff"); /* TTF format */
  src: url("../fonts/OpenSans-Medium.ttf") format("woff2"); /* TTF format */

}

@font-face {
  font-family: 'opensans-semibold'; /* Name your font family */
  src: url("../fonts/OpenSans-SemiBold.ttf") format("truetype"); /* TTF format */
  src: url("../fonts/OpenSans-SemiBold.ttf") format("woff"); /* TTF format */
  src: url("../fonts/OpenSans-SemiBold.ttf") format("woff2"); /* TTF format */
}

@font-face {
  font-family: 'opensans-bold'; /* Name your font family */
  src: url("../fonts/OpenSans-Bold.ttf") format("truetype"); /* TTF format */
  src: url("../fonts/OpenSans-Bold.ttf") format("woff"); /* TTF format */
  src: url("../fonts/OpenSans-Bold.ttf") format("woff2"); /* TTF format */
}

@font-face {
  font-family: 'opensans-condensed-regular'; /* Name your font family */
  src: url("../fonts/OpenSans_Condensed-Regular.ttf") format("truetype"); /* TTF format */
  src: url("../fonts/OpenSans_Condensed-Regular.ttf") format("woff"); /* TTF format */
  src: url("../fonts/OpenSans_Condensed-Regular.ttf") format("woff2"); /* TTF format */
}

@font-face {
  font-family: 'opensans-medium'; /* Name your font family */
  src: url("../fonts/OpenSans_Condensed-Medium.ttf") format("truetype"); /* TTF format */
  src: url("../fonts/OpenSans_Condensed-Medium.ttf") format("woff"); /* TTF format */
  src: url("../fonts/OpenSans_Condensed-Medium.ttf") format("woff2"); /* TTF format */
}

@font-face {
  font-family: 'opensans-condensed-semibold'; /* Name your font family */
  src: url("../fonts/OpenSans_Condensed-SemiBold.ttf") format("truetype"); /* TTF format */
  src: url("../fonts/OpenSans_Condensed-SemiBold.ttf") format("woff"); /* TTF format */
  src: url("../fonts/OpenSans_Condensed-SemiBold.ttf") format("woff2"); /* TTF format */
}

@font-face {
  font-family: 'opensans-condensed-bold'; /* Name your font family */
  src: url("../fonts/OpenSans_Condensed-Bold.ttf") format("truetype"); /* TTF format */
  src: url("../fonts/OpenSans_Condensed-Bold.ttf") format("woff"); /* TTF format */
  src: url("../fonts/OpenSans_Condensed-Bold.ttf") format("woff2"); /* TTF format */
}



:root {
  .btn[aria-disabled="true"] {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
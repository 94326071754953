@import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../../../libs/fontawesome/css/all";
@import "../../../shared/styles/index.css";
@import "./input.css";
@import "./logo.css";
@import "./notice.css";
@import "./powered-by.css";
@import "./login.css";
@import "./alerts.css";

#app {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  font-size: 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  margin: 0;
  padding: 0;
}

#body-content {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  background-color: var(--vp-white);
}

.page {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f1f1f1;
}

#bottom-gutter {
  bottom: 0;
  position: absolute;
  width: 100%;
}

.ReactModalPortal {
  z-index: var(--vp-firstmost-element-z-index);
}

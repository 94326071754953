.logo {
  display: flex;
  flex-direction: row;
  align-items: center;

  .appTitle {
    /* font-family: var(--vp-font-open-sans-condensed-bold); */
    color: var(--vp-skyline-blue);
    font-size:  calc(var(--vp-font-size) * 1.5);
    margin: 0;
    margin-left: calc(var(--vp-spacing) * 0.5);
    overflow-x: hidden;
    overflow-y: hidden;
    text-overflow: ellipsis;
  }

  & img {
    width: 3em;
  }
}
.powered-by {
  .powered-by-text {
    font-size: calc(var(--vp-font-size)*.75);
    text-transform: uppercase;
    color: var(--vp-skyline-blue);
  }

  .skyline-mark {
    width: 3em;
  }

  .product-title {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  .product-name {
    color: var(--vp-skyline-blue);
    font-size: calc(var(--vp-font-size) * 1.5);
    margin-left: calc(var(--vp-spacing) * 0.5);
  }
}
.btn {
  font-family: var(--vp-font-open-sans-bold);
  text-transform: uppercase;
  background-color: var(--vp-action-blue);
  border: 0;
  box-shadow: none;
  &:disabled {
    background-color: var(--vp-secondary-dark-grey);
  }
  &[aria-disabled="true"] {
    background-color: var(--vp-secondary-dark-grey);
  }
  &:active {
    background-color: var(--vp-skyline-blue);
  }
}
.notice {
  font-family: var(--vp-font-open-sans);
  color: var(--vp-skyline-blue);
  background: var(--vp-bg-soft-blue);
  border: 6px solid #FFFFFF;
  border-radius: var(--vp-border-radius-lg);
  padding: calc(var(--vp-spacing) * 1);
  & h4 {
    font-family: var(--vp-font-open-sans-condensed-bold);
    font-size: 1rem;
    text-transform: uppercase;
  }

  & p {
    font-size: calc(var(--vp-font-size) * .75);
    line-height: calc(var(--vp-font-size));
  }
}
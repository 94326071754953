.alerts-container {
  display: flex;
  width: 100%;
  background-color: var(--vp-secondary-red);

  & .alerts {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
  }
}

.gateway-alert {
  align-items: center;
  background-color: var(--vp-secondary-red);
  display: flex;
  padding: 0px 10px;

  & span {
    color: var(--vp-white);
    cursor: pointer;
    font-family:  var(--vp-font-open-sans-condensed);
    font-size: var(--vp-nav-font-size);
  }

  & .alertFaIcon {
    color: var(--vp-white);
    font-size: var(--vp-nav-font-size);
    margin-right: 5px;
  }
}

.modal-container {
  .alerts-modal {
    width: 650px;

    @media screen and (max-width: 768px) {
      width: 400px;
    }

    @media screen and (max-width: 480px) {
      width: 300px;
    }

    .alert-icon {
      color: var(--vp-secondary-orange);
      margin-right: var(--vp-spacing);
    }

    .alerts-modal-content {
      border: none;
      box-shadow: none;
      font-family: var(--vp-font-open-sans-condensed-bold);
      max-height: 40vh;
      overflow-y: auto;
      padding: var(--vp-padding);

      .alert-item {
        border-bottom: var(--vp-boder-line-grey);
        margin: calc(var(--vp-spacing) * .5) 0;
      }

      .alert-title {
        color: var(--vp-medium-blue);
        font-family: var(--vp-font-open-sans-condensed-bold);
        font-size: calc(var(--vp-font-size) * 1.25);
        margin-bottom: calc(var(--vp-spacing) * .5);
      }

      .alert-content {
        color: var(--vp-secondary-dark-grey);
        font-family: var(--vp-font-open-sans);
        font-size: calc(var(--vp-font-size) * 1.15);
      }
    }
  }
}
:root {
  box-shadow-size: 10px;
}

#root {
  font-family: var(--vp-font-open-sans-condensed-bold);
  color: var(--vp-skyline-blue);

  #container-router {
    justify-content: left;
    flex-direction: row;
  }
}

.view-container-wrapper {
  background-image: url('../img/BG-Abstract-Map.webp');
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.view-container-header {
  width: 100%;
  .version {
    margin: 0;
  }
}

.view-container-container {
  height: 100%;
  display: inline-flex;
  align-items: center;
}

.view-container {
  border-radius: var(--vp-border-radius-lg);
  background: white;
  box-shadow: 0px calc(var(--vp-box-shadow-size)*1) calc(var(--vp-box-shadow-size)*7) #C6C6C6;
  padding: calc(var(--vp-spacing) * 2);
  width: 22.5em;
}

.auth-form {
  margin-bottom: calc(var(--vp-spacing) * 1.5);

  .input-container {
    margin-bottom: 15px;

    label {
      display: inline-block;
      max-width: 100%;
      margin-bottom: 5px;
    }
  }
}

.logo {
  padding-bottom: calc(var(--vp-spacing) * 1.25);
}

.login-view-container,
.loggedOut-view-container,
.forgot-password-view-container,
.reset-password-view-container {
  & label {
    text-transform: uppercase;
  }

  & input[type="text"],
  & input[type="password"] {
    font-family: var(--vp-font-open-sans);
    background: var(--vp-bg-medium-grey);
    color: var(--vp-secondary-dark-grey);
    padding: calc(var(--vp-spacing) * 1.25);
    border: 0;
    box-shadow: none;
  }

  .btn {
    font-size: calc(var(--vp-font-size) * .9);
    line-height: calc(var(--vp-font-size) * .9);
    border-radius: var(--vp-border-radius);
    padding: calc(var(--vp-spacing) * .75);
  }

  .submit-container {
    display: flex;
    flex-flow: column;

    .btn {
      width: 100%;

    }
  }

  & a {
    font-family: var(--vp-font-open-sans);
    font-size: calc(var(--vp-font-size));
    color: var(--vp-action-blue);
    cursor: pointer;
    text-decoration: underline;
  }

  .forgot-password,
  .cancel {
    margin-top: 15px;
  }

  .alert {
    font-size: calc(var(--vp-font-size) * .85);
    line-height: var(--vp-font-size);
    border: 0;
    margin-top: 20px;
    margin-bottom: 0;
  }

  .alert-above-body {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.back-link-container {
  position: relative;
  .back-link {
    cursor: pointer;
    position: absolute;
    left: -25px;
  }
}

/* Forgot password specific styles */
.forgot-password-form {
  .help-block {
    text-transform: none;
    color: var(--vp-skyline-blue) !important;
  }
}

.rules {
  & p {
    margin: 0;
  }
  & ul {
    font-family: var(--vp-font-open-sans);
    font-size: calc(var(--vp-font-size) * .9);
    line-height: calc(var(--vp-font-size) * 1.25);
    padding-left: calc(var(--vp-spacing) * 1.5);
  }
}
/* end forgot password */

/* notice specific styles for authentication page */
.auth-notice-wrapper {
  margin: calc(var(--vp-spacing) * 1.5) 0;
  .notice {
    max-width: 75%;
    margin: auto;
  }
}


.spinner {
  display: flex;
  flex-direction: column;
  
  svg {
    margin: auto;
    animation: rotator 1.4s linear infinite;
    
    .path {
      stroke-dasharray: 187;
      stroke-dashoffset: 0;
      transform-origin: center;
      animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
    }

  }
}

@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

@keyframes dash {
  0% { stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 187*0.25;
    transform:rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform:rotate(450deg);
  }
 }
@keyframes colors {
  0% { stroke: #4285F4; }
  25% { stroke: #DE3E35; }
  50% { stroke: #F7C223; }
  75% { stroke: #1B9A59; }
  100% { stroke: #4285F4; }
}

@media screen and (max-width: 480px) {
  .view-container {
    width: 19em;
  }
}

@media screen and (orientation: landscape) and (max-height: 480px) {
  .view-container-wrapper {
    height: unset;
    padding-bottom: calc(var(--vp-spacing) * 1.5);
  }
}